import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import Stack from "@mui/material/Stack";
import UploadImg from "src/components/phase1/Field/uploadAvatar";
import FilterInputWrapper from "../NewLayout/FilterInputWrapperV2";
import { Button, Grid, FormControl, MenuItem, Select } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import SelectInput from "src/components/CustomComponent/SelectInput";
import userAPi from "src/api/MemberApi";
import HasgtagApi from "src/api/HasgtagApi";
import { PenatyAPi, niceApi } from "src/api/index";
import MultipleSelectCheckmarks from "./SelectMuiti";
import "./styles.scss";
import { DateInput } from "../Fields";

import { convertTimeZone, handlSignInTime } from "src/util/convert";
import { getAgeUser, converAgeToMilisecond, IsCeleb, niceCompany } from "src/util/Utils";
import API from "src/api/MemberApi";
import { showError, showSuccess } from "src/util/error";
import { userSettingAPI } from "src/api/index";
import { LoadingContext } from "src/providers/LoadingProvider";
import moment from "moment";
import md5 from "md5";
import {
	Methodverify,
	confirmIsOver19Type,
	genderType,
	getAppName,
	getAppVersion,
} from "src/util/data";

const device = [
	{ title: "Desktop", value: "DESKTOP" },
	{ title: "Phone", value: "PHONE" },
];
const rank = [
	{ title: "정상", value: "NORMAL" },
	{ title: "주의", value: "WARNING" },
	{ title: "위험", value: "RISK" },
];
const ageData = [];
for (let i = 1; i <= 100; i++) {
	ageData.push({ title: i.toString(), value: i });
}

const platform = [
	{ title: "WEB", value: "WEB" },
	{ title: "IOS", value: "IOS" },
	{ title: "AOS", value: "AOS" },
];

const statusAccount = [
	{ title: "정상", value: "NORMAL" },
	{ title: "계정 정지", value: "BLOCK" },
	{ title: "탈퇴", value: "DELETE" },
];
const methodBlock = [{ title: "관리자블럭", value: "ADMINBLOCK" }];
const reasonBlock = [
	{ title: "광고 회원", value: "ADV" },
	{ title: "불량회원", value: "USER_VIOLET" },
	{ title: "불량대화", value: "CONTENT_VIOLET" },
	{ title: "불량사진", value: "IMG_VIOLET" },
];
const reasonDelete = [
	{ title: "광고회원", value: "ADV" },
	{ title: "불량회원", value: "USER_VIOLET_STANDARD" },
	{ title: "관리자탈퇴", value: "ADMIN_DELETE" },
];
const methodDelete = [
	{ title: "본인탈퇴", value: "USER_DELETE" },
	{ title: "관리자 탈퇴", value: "ADMIN_DELETE" },
];
const gender = [
	{ title: "남자", value: "MALE" },
	{ title: "여자", value: "FEMALE" },
];

const UserInfo = ({ hastag, featchHashTag, data, fetchAll, setReloadApiAfterChange }) => {
	const [selectedOptions, setSelectedOptions] = useState([data?.user_hashtags]);
	const [password, setPassword] = useState(null);
	const [niceInfo, setNiceInfo] = useState({
		user_id: null,
		nice_auth_type: 0,
		nice_birthdate: null,
		nice_gender: null,
		nice_mobile_no: null,
		nice_name: null,
		nice_utf8_name: null,
		nice_mobileco: null,
		nice_verify_at_unix_timestamp: null,
	});
	const [isConfirmOver19, setIsConfirmOver19] = useState(false);
	const { showLoading, hideLoading } = useContext(LoadingContext);
	const [urlImg, setUrlImg] = useState(null);
	const [admin_note, setAdmin_note] = useState("");
	const [basicInfo, setBasicInfo] = useState({
		identified_id: "",
		about_me: "",
		nickname: "",
		email: "",
		birthday: data?.birthday ? Number(getAgeUser(data?.birthday)) : -1,
		cs_level: "",
		sex: ""
	});
	const [loginInfomation, setloginInfomation] = useState({
		sign_up_device: null,
		sign_in_device: null,
		sign_up_platform: null,
		sign_in_platform: null,
	});

	const [userSetting, setuserSetting] = useState({
		allowVideoCall: null,
		allowMessage: null,
		payPerCall: null,
		minCallMinutes: null,
	});

	const [status_account, setStatus_account] = useState({
		status_account: "",
		methodBlock: "",
		reasonBlock: "",
		reasonDelete: "",
		methodDelete: "",
	});

	const [dateDelete, setDateDelete] = useState(0);
	const [dateBlock, setDateBlock] = useState(0);

	const [appInfo, setAppInfo] = useState({
		app_version: "",
		app_name: "",
	});

	useEffect(() => {
		setUrlImg(data?.avatar[0]);

		setuserSetting({
			allowVideoCall: data?.user_setting.allow_video_call,
			allowMessage: data?.user_setting.allow_message,
			payPerCall: data?.user_setting.pay_per_call,
			minCallMinutes: data?.user_setting.min_call_minutes,
		});
		setAdmin_note(data?.admin_note);
		setStatus_account({
			status_account: data?.status_account,
			methodBlock: data?.penalty?.type_block,
			reasonBlock: data?.penalty?.reason,
			reasonDelete: data?.penalty?.reason_delete,
			methodDelete: data?.penalty?.type_delete,
		});
		+data?.penalty?.Block_at_unix_timestamp &&
			setDateBlock(new Date(+data?.penalty?.Block_at_unix_timestamp));
		+data?.penalty?.Delete_at_unix_timestamp &&
			setDateDelete(new Date(+data?.penalty?.Delete_at_unix_timestamp));
		setloginInfomation({
			sign_up_device: data?.sign_up_device,
			sign_in_device: data?.sign_in_device,
			sign_up_platform: data?.sign_up_platform,
			sign_in_platform: data?.sign_in_platform,
		});
		setBasicInfo({
			identified_id: data?.identified_id,
			about_me: data?.about_me,
			nickname: data?.nickname,
			email: data?.email,
			birthday: data?.birthday ? getAgeUser(data?.birthday) : "-1",
			cs_level: data?.cs_level,
			sex: data?.sex,
		});
		setNiceInfo({
			nice_mobile_no: data?.nice_infos[0]?.nice_mobile_no,
			nice_auth_type: data?.nice_infos[0]?.nice_auth_type || 0,
			nice_verify_at_unix_timestamp: data?.nice_infos[0]?.nice_verify_at_unix_timestamp,
			nice_birthdate: data?.nice_infos[0]?.nice_birthdate,
			nice_gender: data?.nice_infos[0]?.nice_gender,
			nice_mobileco: data?.nice_infos[0]?.nice_mobileco,
			nice_utf8_name: data?.nice_infos[0]?.nice_utf8_name,
		});

		const isConfirmOver19 = data?.is_over_19 && data?.nice_infos[0]?.id ? true : false;
		setIsConfirmOver19(isConfirmOver19);

		setAppInfo({
			app_name: getAppName(data),
			app_version: getAppVersion(data),
		});
	}, [data]);

	const handleChange = (e, key, action, state) => {
		console.log(e.target.value);
		action({ ...state, [key]: e.target.value });
	};
	const handleAvatar = async (body) => {
		try {
			const res = await userAPi.adminUpdate(body);
			if (res.code === 200) {
				showSuccess();
				fetchAll();
				featchHashTag();
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleHastag = (data) => {
		const id = [];
		data.map((item) => {
			id.push(item.value);
		});
		return id;
	};
	const handleUpdateUserSetting = async () => {
		showLoading();
		const hashTagID = handleHastag(selectedOptions);
		try {
			const body = handlePreUpdate();
			const res = await HasgtagApi.userHashTagUpdate(data?.id, hashTagID);
			const res1 = await userSettingAPI.update(
				{
					allow_video_call: userSetting.allowVideoCall,
					allow_message: userSetting.allowMessage,
					pay_per_call: userSetting.payPerCall,
					min_call_minutes: userSetting.minCallMinutes,
				},
				data.user_setting.id
			);
			await userAPi.update({ ...body }, data.id);
			if (res1.code === 200 && res.code === 200) {
				fetchAll();
				featchHashTag();
				setReloadApiAfterChange(true);
				showSuccess();
			}
			hideLoading();
		} catch (error) {
			showError(error.response.data.message);
		}
	};

	// duplicate name, please change name if you see this line of code, now i can not  fix because i am so busy
	const handleUpdate = async (body) => {
		showLoading();
		try {
			const res = await API.update(body, data.id);
			if (res.code === 200) {
				fetchAll();
				featchHashTag();
			}
			hideLoading();
		} catch (error) {
			hideLoading();
			showError(error.response.data.message);
		}
	};

	const handleBlockAndDeleteAccount = async (body) => {
		showLoading();
		try {
			await API.update({ status_account: body.status_account }, data.id);

			if (data.penalty) {
				// do some thing here
				const res2 = await PenatyAPi.update(
					{
						reason: body.reasonBlock,
						reason_delete: body.reasonDelete,
						type_delete: body.methodDelete,
						type_block: body.methodBlock,
						Block_at_unix_timestamp: dateBlock ? moment(dateBlock).valueOf() : 0,
						Delete_at_unix_timestamp: dateDelete ? moment(dateDelete).valueOf() : 0,
					},
					data.penalty.id
				);

				if (res2.code === 200) {
					fetchAll();
				}
			} else {
				const res2 = await PenatyAPi.add({
					user_id: data?.id,
					reason: body.reasonBlock,
					reason_delete: body.reasonDelete,
					type_delete: body.methodDelete,
					type_block: body.methodBlock,
					Block_at_unix_timestamp: dateBlock ? moment(dateBlock).valueOf() : 0,
					Delete_at_unix_timestamp: dateDelete ? moment(dateDelete).valueOf() : 0,
				});

				if (res2.code === 200) {
					fetchAll();
				}

				// do some thing here
			}
			hideLoading();
		} catch (error) {
			hideLoading();
			showError(error.response.data.message);
		}
	};
	const handleInputChange = (event) => {
		const value = event.target.value;
		setPassword(value);
	};
	const handlePreUpdate = () => {
		const pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,20}$/;
		if (password === null || password === undefined || password === "") {
			const body = {
				...loginInfomation,
				...basicInfo,
				birthday: converAgeToMilisecond(basicInfo.birthday),
			};
			return body;
		} else {
			if (!pattern.test(password)) {
				alert(
					"비밀번호는 하나 이상의 숫자와 하나의 문자를 포함해야 하며 소문자, 대문자, 숫자 및 특수 문자 (@, $, !, %, *, #, ?, &)만 포함하고 길이는 8~20자여야 합니다"
				);
			} else {
				const body = {
					...loginInfomation,
					...basicInfo,
					birthday: converAgeToMilisecond(basicInfo.birthday),
					password: md5(password),
				};
				return body;
			}
		}
	};

	const handleUpdateNice = async () => {
		showLoading();
		try {
			const res = await niceApi.update(niceInfo, data?.nice_infos[0]?.id);
			if (res?.code === 200) {
				fetchAll();
				showSuccess();
			}
		} catch (error) {
			showError(error);
		} finally {
			hideLoading();
		}
	};
	const handleDeleteNice = async () => {
		showLoading();
		try {
			await Promise.all([
				data?.nice_infos[0]?.id && niceApi.delete(data?.nice_infos[0]?.id),
				handleConfirmOver19(false),
			]).then(() => {
				fetchAll();
				showSuccess();
			});
		} catch (error) {
			showError(error);
		} finally {
			hideLoading();
		}
	};

	const handleAddNice = async () => {
		const body = {
			user_id: data?.id,
			nice_auth_type: niceInfo.nice_auth_type,
			nice_birthdate: niceInfo.nice_birthdate,
			nice_gender: niceInfo.nice_gender,
			nice_mobile_no: niceInfo.nice_mobile_no,
			nice_name: niceInfo.nice_name ?? decodeURIComponent(niceInfo.nice_utf8_name) ?? null,
			nice_utf8_name: niceInfo.nice_utf8_name,
			nice_mobileco: niceInfo.nice_mobileco,
			nice_verify_at_unix_timestamp: niceInfo.nice_verify_at_unix_timestamp,
		};
		showLoading();
		try {
			await Promise.all([niceApi.add(body), handleConfirmOver19(true)]).then(() => {
				fetchAll();
				showSuccess();
			});
		} catch (error) {
			showError(error);
		} finally {
			hideLoading();
		}
	};

	const handleSubmitNiceInfo = () => {
		const currentIsOver19 = data?.is_over_19 && data?.nice_infos[0]?.id ? true : false;
		if (!isConfirmOver19) {
			handleDeleteNice();
			return;
		}

		if (!currentIsOver19) {
			handleAddNice();
			return;
		}
		handleUpdateNice();
	};

	const handleConfirmOver19 = async (isOver19) => {
		await userAPi.update({ is_over_19: isOver19 }, data?.id);
	};

	return (
		<>
			<Stack spacing={2}>
				{/* avatar */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>사진정보</p>
					</div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							padding: "20px",
						}}
					>
						<div className="user-avatar-container" style={{ flex: 3 }}>
							<UploadImg thumbnail={urlImg} setUrlImg={setUrlImg} />
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-around",
									marginLeft: "10px",
								}}
							>
								<Button
									variant="contained"
									sx={{
										background: "#FAFAFA ",
										border: "1px solid #E1E1E1",
										borderRadius: "10px",
										color: "black",
									}}
									onClick={() =>
										data?.avatar.length > 0 &&
										handleAvatar({
											type: "REMOVE_AVATAR",
											photos: [data?.avatar[0]],
											user_id: data?.id,
										})
									}
								>
									삭제
								</Button>
								<Button
									variant="contained"
									sx={{
										background: "#3B3B3B ",
										border: "1px solid #E1E1E1",
										borderRadius: "10px",
									}}
									onClick={() =>
										urlImg &&
										handleAvatar({
											type: "ADD_AVATAR",
											photos: [urlImg],
											user_id: data?.id,
										})
									}
								>
									사진 등록
								</Button>
							</div>
						</div>
						{IsCeleb(data) && (
							<div style={{ flex: 1 }}>
								<FilterInputWrapper label={"총 적립금액"}>
									<input type="text" readOnly className="custom-input" />
								</FilterInputWrapper>
								<FilterInputWrapper label={"총 환급금액"}>
									<input type="text" readOnly className="custom-input" />
								</FilterInputWrapper>
								<FilterInputWrapper label={"현재 보유금액"}>
									<input type="text" readOnly className="custom-input" />
								</FilterInputWrapper>
							</div>
						)}
					</div>
				</div>

				{/*basic info */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>기본정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* id */}
							<FilterInputWrapper label={/*sCeleb(data) ? "셀럽 ID" :*/ "회원 ID"}>
								<input
									type="text"
									value={basicInfo?.identified_id}
									onChange={(e) => handleChange(e, "identified_id", setBasicInfo, basicInfo)}
									className="custom-input"
								/>
							</FilterInputWrapper>
							{/* gender */}
							<FilterInputWrapper label={"성별"}>
								<SelectInput
									data={gender}
									className="select"
									value={basicInfo?.sex}
									handleChange={(e) => {
										handleChange(e, "sex", setBasicInfo, basicInfo);
									}}
								/>
							</FilterInputWrapper>

							{/* hasgtag */}
							{/* <FilterInputWrapper label={"관심사"}> */}
							{/* <input
									type="text"
									value={renderUserHashTag(data?.user_hashtags)}
									readOnly
									className="custom-input"
								/> */}
							<div className="container-muitiselect">
								<div className="label-muiselect">관심사</div>
								<MultipleSelectCheckmarks
									options={hastag}
									getHashTag={() => {}}
									selectedOptions={selectedOptions}
									setSelectedOptions={setSelectedOptions}
									data={data?.user_hashtags}
								/>
							</div>
							{/* </FilterInputWrapper> */}

							{/* desc*/}
							<FilterInputWrapper label={"비밀번호"}>
								<input
									type="text"
									value={password}
									onChange={(e) => handleInputChange(e)}
									// title="Mật khẩu phải chứa ít nhất một ký tự chữ cái, một chữ số, và có độ dài từ 8 đến 20 ký tự."
									className="custom-input"
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* nickname */}
							<FilterInputWrapper label={"닉네임"}>
								<input
									type="text"
									value={basicInfo.nickname}
									onChange={(e) => handleChange(e, "nickname", setBasicInfo, basicInfo)}
									className="custom-input"
								/>
							</FilterInputWrapper>
							{/* email */}
							<FilterInputWrapper label={"이메일"}>
								<input
									type="text"
									value={basicInfo.email}
									onChange={(e) => handleChange(e, "email", setBasicInfo, basicInfo)}
									className="custom-input"
								/>
							</FilterInputWrapper>
							{/* partner */}
							<FilterInputWrapper label={"파트너"}>
								<input type="text" value={data?.agency?.nickname} className="custom-input" />
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* birthday */}
							<FilterInputWrapper label={"나이"}>
								<SelectInput
									data={ageData}
									className="select"
									value={basicInfo.birthday}
									handleChange={(e) => {
										handleChange(e, "birthday", setBasicInfo, basicInfo);
									}}
								/>
							</FilterInputWrapper>
							{/* status */}
							<FilterInputWrapper label={"상태"}>
								<input
									type="text"
									className="custom-input-disabled"
									disabled
									value={data?.status_account}
								/>
							</FilterInputWrapper>
							{/* level */}
							<FilterInputWrapper label={"등급"}>
								{/* <input type="text" readOnly className="custom-input" /> */}
								<SelectInput
									data={rank}
									className="select"
									value={basicInfo?.cs_level}
									handleChange={(e) => {
										handleChange(e, "cs_level", setBasicInfo, basicInfo);
									}}
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div>

				{/*Registration / login information*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>가입 · 로그인정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* sigun-up day */}
							<FilterInputWrapper label={"가입 일자"}>
								<input
									type="text"
									value={convertTimeZone(+data?.created_at_unix_timestamp)}
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
							{/* sign up device */}
							<FilterInputWrapper label={"가입 디바이스"}>
								<SelectInput
									data={device}
									className="select"
									value={loginInfomation.sign_up_device}
									handleChange={(e) => {
										handleChange(e, "sign_up_device", setloginInfomation, loginInfomation);
									}}
								/>
							</FilterInputWrapper>
							{/* sign up platfrom */}
							<FilterInputWrapper label={"가입 플랫폼"}>
								<SelectInput
									data={platform}
									className="select"
									value={loginInfomation.sign_up_platform}
									handleChange={(e) => {
										handleChange(e, "sign_up_platform", setloginInfomation, loginInfomation);
									}}
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* sigun-in day */}
							<FilterInputWrapper label={"로그인 일자"}>
								<input
									type="text"
									value={handlSignInTime(
										data?.sign_in_time_unix_timestamp,
										data?.last_open_app_unix_timestamp
									)}
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
							{/* sign in  device */}
							<FilterInputWrapper label={"로그인 디바이스"}>
								<SelectInput
									data={device}
									className="select"
									value={loginInfomation.sign_in_device}
									handleChange={(e) => {
										handleChange(e, "sign_in_device", setloginInfomation, loginInfomation);
									}}
								/>
							</FilterInputWrapper>
							{/* sign in platfrom */}
							<FilterInputWrapper label={"로그인 플랫폼"}>
								<SelectInput
									data={platform}
									className="select"
									value={loginInfomation.sign_in_platform}
									handleChange={(e) => {
										handleChange(e, "sign_in_platform", setloginInfomation, loginInfomation);
									}}
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* OS app download  */}
							<FilterInputWrapper label={"설치한 앱 명"}>
								<input
									type="text"
									value={appInfo.app_name}
									readOnly
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
							{/* app sersion */}
							<FilterInputWrapper label={"설치한 앱 버전"}>
								<input
									type="text"
									value={appInfo.app_version}
									readOnly
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
							{/* app sersion */}
							<FilterInputWrapper label={"설치한 플랫폼"}>
								<input
									type="text"
									value={data?.app_platform}
									readOnly
									className="custom-input-disabled"
									disabled
								/>
							</FilterInputWrapper>
						</Grid>
					</Grid>
				</div>
				{/*push  setting*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>푸시 설정</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* video call  */}
							<FilterInputWrapper label={"영상통화"}>
								<FormControl sx={{ height: "42px" }}>
									<Select
										className="select"
										sx={{
											"& legend": { display: "none" },
											"& fieldset": { top: 0 },
											width: "136px",
										}}
										value={userSetting.allowVideoCall}
										onChange={(e) => handleChange(e, "allowVideoCall", setuserSetting, userSetting)}
									>
										<MenuItem value={true}>ON</MenuItem>
										<MenuItem value={false}>OFF</MenuItem>
									</Select>
								</FormControl>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* chat */}
							<FilterInputWrapper label={"문자채팅"}>
								<FormControl sx={{ height: "42px" }}>
									<Select
										className="select"
										sx={{
											"& legend": { display: "none" },
											"& fieldset": { top: 0 },
											width: "136px",
										}}
										value={userSetting.allowMessage}
										onChange={(e) => handleChange(e, "allowMessage", setuserSetting, userSetting)}
									>
										<MenuItem value={true}>ON</MenuItem>
										<MenuItem value={false}>OFF</MenuItem>
									</Select>
								</FormControl>
							</FilterInputWrapper>
						</Grid>
					</Grid>
					{/* button */}
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							variant="contained"
							sx={{
								background: "#3B3B3B ",
								border: "1px solid #E1E1E1",
								borderRadius: "10px",
								width: "300px",
								height: "34px",
							}}
							onClick={handleUpdateUserSetting}
						>
							회원 정보 변경
						</Button>
					</div>
				</div>

				{/* verify*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>본인 인증 정보</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* verify */}
							<FilterInputWrapper label={"본인 인증"}>
								<SelectInput
									data={confirmIsOver19Type}
									className="select"
									value={isConfirmOver19}
									handleChange={() => setIsConfirmOver19(!isConfirmOver19)}
								/>
							</FilterInputWrapper>
							{/* name */}
							<FilterInputWrapper label={"이름"}>
								{isConfirmOver19 ? (
									<input
										type="text"
										className="custom-input"
										value={decodeURIComponent(niceInfo?.nice_utf8_name || "")}
										onChange={(e) => {
											setNiceInfo({
												...niceInfo,
												nice_utf8_name: encodeURIComponent(e.target.value),
												nice_name: e.target.value,
											});
										}}
									/>
								) : (
									<input type="text" className="custom-input-disabled" disabled value={""} />
								)}
							</FilterInputWrapper>
							{/* phone verify */}
							<FilterInputWrapper label={"전화 번호"}>
								{isConfirmOver19 ? (
									<input
										type="text"
										className="custom-input"
										value={niceInfo?.nice_mobile_no}
										onChange={(e) => handleChange(e, "nice_mobile_no", setNiceInfo, niceInfo)}
									/>
								) : (
									<input type="text" className="custom-input-disabled" disabled value={""} />
								)}
							</FilterInputWrapper>
						</Grid>

						<Grid item xs={4}>
							{/* method verify */}
							<FilterInputWrapper label={"본인 인증 방식"}>
								{isConfirmOver19 ? (
									<SelectInput
										data={Methodverify}
										className="select"
										value={niceInfo?.nice_auth_type}
										handleChange={(e) => handleChange(e, "nice_auth_type", setNiceInfo, niceInfo)}
									/>
								) : (
									<input disabled className="custom-input-disabled" value={"미인증"} />
								)}
							</FilterInputWrapper>
							{/* gender*/}
							<FilterInputWrapper label={"성별"}>
								{isConfirmOver19 ? (
									<SelectInput
										data={genderType}
										className="select"
										value={niceInfo?.nice_gender}
										handleChange={(e) => handleChange(e, "nice_gender", setNiceInfo, niceInfo)}
									/>
								) : (
									<input type="text" className="custom-input-disabled" disabled value={""} />
								)}
							</FilterInputWrapper>
							{/* network name */}
							<FilterInputWrapper label={"통신사"}>
								{isConfirmOver19 ? (
									<input
										type="text"
										className="custom-input"
										value={niceCompany(niceInfo?.nice_mobileco)}
										onChange={(e) => handleChange(e, "nice_mobileco", setNiceInfo, niceInfo)}
									/>
								) : (
									<input type="text" className="custom-input-disabled" disabled value={""} />
								)}
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* day verify */}
							<FilterInputWrapper label={"인증 날짜"}>
								{isConfirmOver19 ? (
									<DateInput
										className="custom-input"
										date={Number(niceInfo?.nice_verify_at_unix_timestamp)}
										setDate={(newDate) => {
											setNiceInfo({
												...niceInfo,
												nice_verify_at_unix_timestamp: newDate.getTime(),
											});
										}}
									/>
								) : (
									<input
										type="text"
										readOnly
										className="custom-input-disabled"
										disabled
										value={""}
									/>
								)}
							</FilterInputWrapper>
							{/* D.O.Bs */}
							<FilterInputWrapper label={"생년월일"}>
								{isConfirmOver19 ? (
									<DateInput
										className="custom-input"
										date={Number(niceInfo.nice_birthdate)}
										setDate={(newDate) => {
											setNiceInfo({
												...niceInfo,
												nice_birthdate: newDate.getTime(),
											});
										}}
									/>
								) : (
									<input type="text" className="custom-input-disabled" disabled value={""} />
								)}
							</FilterInputWrapper>
						</Grid>
					</Grid>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							variant="contained"
							sx={{
								background: "#3B3B3B ",
								border: "1px solid #E1E1E1",
								borderRadius: "10px",
								width: "300px",
								height: "34px",
							}}
							onClick={() => handleSubmitNiceInfo()}
						>
							회원 정보 변경
						</Button>
					</div>
				</div>

				{/* block and delete account */}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>계정정지 및 탈퇴</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 2 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={4}>
							{/* status account */}
							<FilterInputWrapper label={"상태"}>
								<SelectInput
									data={statusAccount}
									className="select"
									value={status_account.status_account}
									handleChange={(e) => {
										handleChange(e, "status_account", setStatus_account, status_account);
									}}
								/>
							</FilterInputWrapper>
							{/* METHOD BLOCK */}
							<FilterInputWrapper label={"계정정지방식"}>
								<SelectInput
									data={methodBlock}
									className="select"
									value={status_account.methodBlock}
									handleChange={(e) => {
										handleChange(e, "methodBlock", setStatus_account, status_account);
									}}
								/>
							</FilterInputWrapper>
							{/* METHOD DELETE */}
							<FilterInputWrapper label={"탈퇴방식"}>
								<SelectInput
									data={methodDelete}
									className="select"
									value={status_account.methodDelete}
									handleChange={(e) => {
										handleChange(e, "methodDelete", setStatus_account, status_account);
									}}
								/>
							</FilterInputWrapper>
						</Grid>

						<Grid item xs={4}>
							{/* REASON BLOCK */}
							<FilterInputWrapper label={"계정정지사유"}>
								<SelectInput
									data={reasonBlock}
									className="select"
									value={status_account.reasonBlock}
									handleChange={(e) => {
										handleChange(e, "reasonBlock", setStatus_account, status_account);
									}}
								/>
							</FilterInputWrapper>
							{/* REASON DELETE */}
							<FilterInputWrapper label={"탈퇴사유"}>
								<SelectInput
									data={reasonDelete}
									className="select"
									value={status_account.reasonDelete}
									handleChange={(e) => {
										handleChange(e, "reasonDelete", setStatus_account, status_account);
									}}
								/>
							</FilterInputWrapper>
						</Grid>
						<Grid item xs={4}>
							{/* DATE BLOCK */}
							<FilterInputWrapper label={"계정정지일시"}>
								<DateInput
									date={dateBlock}
									setDate={setDateBlock}
									placeholder={"YYYY/MM/DD HH:mm"}
									dateFormat={"yyyy/MM/dd HH:mm"}
									isShowTime={true}
								/>
							</FilterInputWrapper>
							{/* DATE DELETE */}
							<FilterInputWrapper label={"탈퇴일시"}>
								<DateInput date={dateDelete} setDate={setDateDelete} />
							</FilterInputWrapper>
						</Grid>
					</Grid>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							variant="contained"
							sx={{
								background: "#3B3B3B ",
								border: "1px solid #E1E1E1",
								borderRadius: "10px",
								width: "300px",
								height: "34px",
							}}
							onClick={() => {
								handleBlockAndDeleteAccount(status_account);
							}}
						>
							계정 상태 변경
						</Button>
					</div>
				</div>

				{/* admin not*/}
				<div>
					<div
						style={{
							background: "#F6F6F6 0% 0% no-repeat padding-box",
							border: "1px solid #E6ECF3",
							height: "50px",
							padding: "13px 22px",
						}}
					>
						<p>관리자 메모</p>
					</div>

					<Grid
						container
						rowSpacing={1}
						sx={{ mt: 1, p: 1 }}
						columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					>
						<Grid item xs={12} sx={{ display: "flex" }}>
							<TextareaAutosize
								style={{ width: "calc(100% - 146px)", height: 200, border: "1px solid black" }}
								value={admin_note}
								onChange={(e) => setAdmin_note(e.target.value)}
							/>
							<div style={{ display: "flex", alignItems: "flex-end" }}>
								<Button
									onClick={() => {
										handleUpdate({
											admin_note: admin_note,
										});
									}}
									variant="contained"
									sx={{
										background: "#3B3B3B ",
										border: "1px solid #E1E1E1",
										borderRadius: "10px",
										width: "136px",
										height: "54px",
										marginLeft: "10px",
									}}
								>
									저장
								</Button>
							</div>
						</Grid>
					</Grid>
				</div>
			</Stack>
		</>
	);
};

export default UserInfo;
