import React from "react";
import { ROUTE } from "src/util/ROUTER";

const Dashboard = React.lazy(() => import("./Pages/dashboard/Dashboard"));

const EmployeeList = React.lazy(() => import("./Pages/employee/EmployeeList"));
const EmployeeCRUD = React.lazy(() => import("./Pages/employee/EmployeeCRUD"));

const InquiryCRUD = React.lazy(() => import("./Pages/inquiry/InquiryCRUD"));

const ConversationList = React.lazy(() => import("./Pages/ManageCeleb/ChatCeleb"));

const NotificationList = React.lazy(() => import("./Pages/notification/NotificationList"));
const SMSPush = React.lazy(() => import("./Pages/Managements/Function/SMSPush"));
const NotificationCRUD = React.lazy(() => import("./Pages/notification/NotificationCRUD"));
const AngencyPushNotification = React.lazy(() => import("./Pages/notification/AgencyNotification"));
const SMSLog = React.lazy(() => import("./Pages/Managements/Function/SMSLog"));

const ContentList = React.lazy(() => import("./Pages/Content/ContentList"));
const Version = React.lazy(() => import("./Pages/Version/Version"));

const ContentCRUD = React.lazy(() => import("./Pages/Content/ContentCRUD"));
const ContentDetail = React.lazy(() => import("./Pages/Content/ContentDetail"));

// const Banner = React.lazy(() => import("./Pages/Banner/Banner"));
// const BannerCRUD = React.lazy(() => import("./Pages/Banner/BannerCRUD"));
//add new
const Banner = React.lazy(() => import("./Pages/BannerV2/BannerV2"));
const BannerCRUD = React.lazy(() => import("./Pages/BannerV2/BannerV2_CRUD"));
// add new
const DefaultMessList = React.lazy(() => import("./Pages/DeFaultMess/DefaultMessList"));
const DefaultMessCRUD = React.lazy(() => import("./Pages/DeFaultMess/DefaultMessCRUD"));
const DefaultMessDetail = React.lazy(() => import("./Pages/DeFaultMess/DefaultMessDetail"));

const PostCelebList = React.lazy(() => import("./Pages/ManageCeleb/PostCelebList"));
const AcceptCelebList = React.lazy(() => import("./Pages/ManageCeleb/AcceptCelebList"));
const FollowCelebList = React.lazy(() => import("./Pages/ManageCeleb/FollowCeleb"));
const SubcriptionCelebList = React.lazy(() => import("./Pages/ManageCeleb/SubcriptionCeleb"));
const MonthLySaleListList = React.lazy(() =>
	import("./Pages/SaleStatistic/MonthlySale/MonthLySaleList")
);
const DailySaleListList = React.lazy(() => import("./Pages/SaleStatistic/DailySale/DailySaleList"));
const PurchaseHistoryList = React.lazy(() =>
	import("./Pages/SaleStatistic/PurchaseHistory/PurchaseHistoryList")
);
const SubscriberHistoryList = React.lazy(() =>
	import("./Pages/SaleStatistic/SubscriberHistory/SubscriberHistoryList")
);
const WebPurchaseHistoryList = React.lazy(() =>
	import("./Pages/SaleStatistic/WebPurchaseHistory/WebPurchaseHistoryList")
);
const starCelebaccumulated = React.lazy(() =>
	import("./Pages/SaleStatistic/starSataticCeleb/StarStatisticCelebList")
);
const CelebConnectionStatus = React.lazy(() =>
	import("./Pages/SaleStatistic/CelebConnectionStatus/index")
);
const ActivityStatusCelebManagement = React.lazy(() =>
	import("./Pages/ManageCeleb/ActivityStatusCelebManagement/index")
);
const ASPPluskitCelebManagement = React.lazy(() =>
	import("./Pages/ManageCeleb/ASPPluskitCelebManagement/index")
);
const ASPAccountTestingManagement = React.lazy(() =>
	import("./Pages/ManageCeleb/ASPAccountTestingManagement/index")
);
const BlockUserList = React.lazy(() => import("./Pages/User Management/BlockUser"));
const ReportUserList = React.lazy(() => import("./Pages/User Management/ReportUser"));
const ProfileImageList = React.lazy(() => import("./Pages/User Management/ProfileImage"));
const ApprovePost = React.lazy(() => import("./Pages/User Management/ApprovePost1"));
const Intermediaryfee = React.lazy(() => import("./Pages/ManageCeleb/Intermediaryfee"));
const UserList = React.lazy(() => import("./Pages/User Management/ListUser"));
const ListUserInstagram = React.lazy(() => import("./Pages/User Management/ListUserInstagram"));
const SettingDefaultMess = React.lazy(() => import("./Pages/ManageCeleb/SettingDefaultMess"));
const DefaultMess = React.lazy(() => import("./Pages/ManageCeleb/DefaultMess"));
const SettingDefaultMessII = React.lazy(() => import("./Pages/ManageCeleb/SettingDefaultMessII"));
const DefaultMessII = React.lazy(() => import("./Pages/ManageCeleb/DefaultMessII"));
const DeleteUser = React.lazy(() => import("./Pages/User Management/DeleteUser"));
const Term = React.lazy(() => import("./Pages/Board/Terms"));
const PersonalInfomation = React.lazy(() => import("./Pages/Board/PersonnalInfomation"));
const Noitice = React.lazy(() => import("./Pages/Board/Noitice/Noitice"));
const NoiticeCrud = React.lazy(() => import("./Pages/Board/Noitice/NoiticeCrud"));
const Inquiry = React.lazy(() => import("./Pages/Board/Inquiry/Inquiry"));
const FAQ = React.lazy(() => import("./Pages/Board/FAQ/FAQ"));
const FAQCurd = React.lazy(() => import("./Pages/Board/FAQ/FAQCrud"));

// const HashtagList = React.lazy(() => import("./Pages/ManageCeleb/Hasgtag/HasgtagList"));
// const HashtagCRUD = React.lazy(() => import("./Pages/ManageCeleb/Hasgtag/HasgtagCRUD"));

const HashtagList = React.lazy(() => import("./Pages/Hasgtag/HasgtagList"));
const HashtagCRUD = React.lazy(() => import("./Pages/Hasgtag/HasgtagCRUD"));

const HashtagLoungeList = React.lazy(() => import("./Pages/HasgtagLounge/HasgtagList"));
const HashtagLoungeCRUD = React.lazy(() => import("./Pages/HasgtagLounge/HasgtagCRUD"));
const HashtagASPList = React.lazy(() => import("./Pages/HasgtagASP/HasgtagASPList"));
const HashtagASPCRUD = React.lazy(() => import("./Pages/HasgtagASP/HasgtagASPCRUD"));

const CategoryList = React.lazy(() => import("./Pages/Category/CaterogyList"));
const CategoryCRUD = React.lazy(() => import("./Pages/Category/CaterogyCRUD"));
const VideoCallLog = React.lazy(() => import("./Pages/ChatManagement/videoCallLog"));
const ChatLog = React.lazy(() => import("./Pages/ChatManagement/ChatLog"));
const CelebWithdraw = React.lazy(() => import("./Pages/PaymentManagement/CelebWithdraw"));
const InappPayment = React.lazy(() => import("./Pages/PaymentManagement/InappPayment"));
const StarLog = React.lazy(() => import("./Pages/StarManagement/StarLog"));
const GiftLog = React.lazy(() => import("./Pages/StarManagement/GiftLog"));
const CelebRequestWithdraw = React.lazy(() =>
	import("./Pages/PaymentManagement/CelebRequestWithdraw")
);
const AgencyWithdraw = React.lazy(() => import("./Pages/PaymentManagement/AgencyWithdraw"));
const AgencyRequestWithdraw = React.lazy(() =>
	import("./Pages/PaymentManagement/AgencyRequestWithdraw")
);
const DiscoveryList = React.lazy(() => import("./Pages/Representative/Discover/Discover"));
const DiscoveryCRUD = React.lazy(() => import("./Pages/Representative/Discover/DiscoverCrud"));
const PoupularList = React.lazy(() => import("./Pages/Representative/Popular/Search"));
const PoupularCRUD = React.lazy(() => import("./Pages/Representative/Popular/SearchCrud"));
const RecommendList = React.lazy(() => import("./Pages/Representative/recommend/Search"));
const RecommendCRUD = React.lazy(() => import("./Pages/Representative/recommend/SearchCrud"));
const ListCelebSetOrder = React.lazy(() => import("./Pages/Representative/recommend/ListUser"));
const SearchList = React.lazy(() => import("./Pages/Representative/Search/Search"));
const SearchCRUD = React.lazy(() => import("./Pages/Representative/Search/SearchCrud"));
const Agency = React.lazy(() => import("./Pages/Managements/Agency"));
const AgencyCurd = React.lazy(() => import("./Pages/Managements/Agency/AgencyCrud"));

const BannerASP = React.lazy(() => import("./Pages/Banner_asp/Banner_asp"));
const BannerASPCRUD = React.lazy(() => import("./Pages/Banner_asp/Banner_asp_CRUD"));
const ReelsASP = React.lazy(() => import("./Pages/Reels_asp/Reels_asp"));
const Reels = React.lazy(() => import("./Pages/Reels/Reels"));
const DailySaleASP = React.lazy(() => import("./Pages/PaymentManagementASP/DailySaleASP"));
const DailySaleASPDetail = React.lazy(() =>
	import("./Pages/PaymentManagementASP/DailySaleASPDetail/DailySaleASPDetail")
);
const MonthlySaleASP = React.lazy(() => import("./Pages/PaymentManagementASP/MonthlySaleASP"));
const InquiryASP = React.lazy(() => import("./Pages/BoardASP/InquiryASP/InquiryASP"));
const InquiryASPCRUD = React.lazy(() => import("./Pages/BoardASP/InquiryASP/InquiryASPCRUD"));
const StarLogASP = React.lazy(() => import("./Pages/LogManagement/StarLogASP"));
const CoinLogASP = React.lazy(() => import("./Pages/LogManagement/CoinLogASP"));
const ListUserASP = React.lazy(() => import("./Pages/UserManagementASP/ListUserASP"));
const VideoChatLogASP = React.lazy(() => import("./Pages/LogManagement/VideoChatLogASP"));
const ChatLogASP = React.lazy(() => import("./Pages/LogManagement/ChatLogASP"));
const ProfileImageASP = React.lazy(() => import("./Pages/UserManagementASP/ProfileImageASP"));
const ReportUserASP = React.lazy(() => import("./Pages/UserManagementASP/ReportUserASP"));
const SettingAutoMessASP = React.lazy(() => import("./Pages/ManageCeleb/SettingAutoMessASP"));
const AutoMessASP = React.lazy(() => import("./Pages/ManageCeleb/AutoMessASP"));
const IntroASP = React.lazy(() => import("./Pages/Intro_ASP/IntroAsp"));
const PluskitVideoManagement = React.lazy(() =>
	import("./Pages/PluskitVideoManagement/PluskitVideoManagement")
);
const IntroAspCRUD = React.lazy(() => import("./Pages/Intro_ASP/IntroAspCRUD"));
const TimeZoneAccessStatistics = React.lazy(() =>
	import("./Pages/StatisticsManagement/TimeZoneAccessStatistics/index")
);
const DailyAccessStatistics = React.lazy(() =>
	import("./Pages/StatisticsManagement/DailyAccessStatistics/index")
);
const AccessStatisticsDetail = React.lazy(() =>
	import("./Pages/StatisticsManagement/AccessStatisticsDetail/index")
);
const OnOffPlusKitManagement = React.lazy(() =>
	import("./Pages/ManageCeleb/OnOffPlusKitManagement/index")
);
const VideoCallErrorStatistics = React.lazy(() =>
	import("./Pages/StatisticsManagement/VideoCallErrorStatistics/index")
);
const GiftRegistrationManagement = React.lazy(() =>
	import("./Pages/GiftRegistrationManagement/index")
);

const PluskitPurchaseLogASP = React.lazy(() =>
	import("./Pages/LogManagement/PluskitPurchaseLogASP")
);

const routes = [
	// if have time please change this
	{ path: ROUTE.HOME, exact: true, name: "Dashboard", component: Dashboard },
	{ path: ROUTE.POPULAR, name: "Policy", component: PoupularList, exact: true },
	{ path: ROUTE.CREATE_POPULAR, name: "Create", component: PoupularCRUD },
	{ path: ROUTE.EDIT_POPULAR, name: "Edit", component: PoupularCRUD },

	{ path: ROUTE.REPRESENT, name: "Policy", component: RecommendList, exact: true },
	{ path: ROUTE.CREATE_REPRESENT, name: "Create", component: RecommendCRUD },
	{ path: ROUTE.SET_ORDER_REPRESENT, name: "Set order", component: ListCelebSetOrder },
	{ path: ROUTE.EDIT_REPRESENT, name: "Edit", component: RecommendCRUD },

	{ path: ROUTE.BANNER, exact: true, name: "Banner", component: Banner },
	{ path: ROUTE.CREATE_BANNER, exact: true, name: "Create", component: BannerCRUD },
	{ path: ROUTE.UPDATE_BANNER, exact: true, name: "Update", component: BannerCRUD },

	{ path: ROUTE.BANNER_ASP, exact: true, name: "BannerASP", component: BannerASP },
	{ path: ROUTE.CREATE_BANNER_ASP, exact: true, name: "Create", component: BannerASPCRUD },
	{ path: ROUTE.UPDATE_BANNER_ASP, exact: true, name: "Update", component: BannerASPCRUD },
	{ path: ROUTE.REELS_ASP, exact: true, name: "Reels", component: ReelsASP },

	{ path: ROUTE.HASHTAG, exact: true, name: "HashTag", component: HashtagList },
	{ path: ROUTE.CREATE_HASHTAG, exact: true, name: "CreateHashtag", component: HashtagCRUD },
	{ path: ROUTE.EDIT_HASHTAG, exact: true, name: "EditHashtag", component: HashtagCRUD },

	{ path: ROUTE.HASHTAG_LOUNGE, exact: true, name: "HashTagLounge", component: HashtagLoungeList },
	{
		path: ROUTE.CREATE_HASHTAG_LOUNGE,
		exact: true,
		name: "CreateHashTagLounge",
		component: HashtagLoungeCRUD,
	},
	{
		path: ROUTE.EDIT_HASHTAG_LOUNGE,
		exact: true,
		name: "EditHashTagLounge",
		component: HashtagLoungeCRUD,
	},

	{ path: ROUTE.HASHTAG_ASP, exact: true, name: "HashtagASP", component: HashtagASPList },
	{
		path: ROUTE.CREATE_HASHTAG_ASP,
		exact: true,
		name: "CreateHashtagASP",
		component: HashtagASPCRUD,
	},
	{ path: ROUTE.EDIT_HASHTAG_ASP, exact: true, name: "EditHashtagASP", component: HashtagASPCRUD },

	{ path: ROUTE.CATEGORY, exact: true, name: "Category", component: CategoryList },
	{ path: ROUTE.CREATE_CATEGORY, exact: true, name: "CreateCategory", component: CategoryCRUD },
	{ path: ROUTE.EDIT_CATEGORY, exact: true, name: "EditCategory", component: CategoryCRUD },

	{ path: ROUTE.EMPLOYEE, name: "Employee", component: EmployeeList, exact: true },
	{ path: ROUTE.CREATE_EMPLOYEE, name: "CreateEmployee", component: EmployeeCRUD },
	{ path: ROUTE.UPDATE_EMPLOYEE, name: "UpdateEmployee", component: EmployeeCRUD },

	{ path: ROUTE.CREATE_INQUIRY, name: "Create", component: InquiryCRUD },
	{ path: ROUTE.ANSWER_INQUIRY, name: "Answer", component: InquiryCRUD },

	{ path: ROUTE.DEFAULT_MESSAGE, name: "Default message", component: DefaultMessList, exact: true },
	{ path: ROUTE.CREATE_DEFAULT_MESSAGE, name: "Create", component: DefaultMessCRUD },
	{ path: ROUTE.EDIT_DEFAULT_MESSAGE, name: "Edit", component: DefaultMessCRUD },
	{ path: ROUTE.DETAIL_DEFAULT_MESSAGE, name: "Detail", component: DefaultMessDetail },

	{ path: ROUTE.POLICY, name: "Policy", component: ContentList, exact: true },
	{ path: ROUTE.VERSION, name: "Version", component: Version, exact: true },

	{ path: ROUTE.CREATE_POLICY, name: "Create", component: ContentCRUD },
	{ path: ROUTE.EDIT_POLICY, name: "Edit", component: ContentCRUD },
	{ path: ROUTE.DETAIL_POLICY, name: "Detail", component: ContentDetail },

	{ path: ROUTE.CONVERSATION_USER, name: "Conversation", component: ConversationList },

	{ path: ROUTE.PUSH_LOG, name: "Notification", component: NotificationList, exact: true },
	{ path: ROUTE.SMS_PUSH, name: "SMSPush", component: SMSPush },
	{ path: ROUTE.PUSH_NOTIFICATION, name: "Create", component: NotificationCRUD },
	{ path: ROUTE.AGENCY_PUSH_NOTIFICATION, name: "Create", component: AngencyPushNotification },
	{ path: ROUTE.SMS_LOG, name: "SMSLog", component: SMSLog },
	{ path: ROUTE.INTERMEDIARY_FEE, name: "Create", component: Intermediaryfee },
	{ path: ROUTE.UPDATE_PUSH_LOG, name: "Update", component: NotificationCRUD },

	{ path: ROUTE.REPORT, name: "Report user", component: ReportUserList, exact: true },

	// new layout cms
	{ path: ROUTE.POST_CELEB, name: "PostCeleb", component: PostCelebList },
	{
		path: ROUTE.CONVERSATION_CELEB,
		name: "Conversation",
		component: ConversationList,
		exact: true,
	},
	{ path: ROUTE.ACCEPT_CELEB, name: "Accept", component: AcceptCelebList, exact: true },
	{ path: ROUTE.FOLLOW_CELEB, name: "Follow", component: FollowCelebList, exact: true },
	{
		path: ROUTE.SUBSCRIPTION_CELEB,
		name: "Supcription",
		component: SubcriptionCelebList,
		exact: true,
	},
	{ path: ROUTE.BLOCK_USER, name: "BlockUser", component: BlockUserList, exact: true },
	{
		path: ROUTE.ACCEPT_IMAGE_PROFILE,
		name: "Profile Image",
		component: ProfileImageList,
		exact: true,
	},
	{ path: ROUTE.LIST_USER, name: "List User", component: UserList, exact: true },
	{ path: ROUTE.DELETE_ACCOUNT, name: "Delete User", component: DeleteUser, exact: true },
	{ path: ROUTE.TERMS_OF_SERVICE, name: "Term of service", component: Term, exact: true },
	{ path: ROUTE.CELEB_WITHDRAW, name: "CelebWithdraw", component: CelebWithdraw, exact: true },
	{
		path: ROUTE.INAPP_PAYMENT_WITHDRAW,
		name: "InappPayment",
		component: InappPayment,
		exact: true,
	},
	{ path: ROUTE.STAR_LOG, name: "StarLog", component: StarLog, exact: true },
	{ path: ROUTE.GIFT_LOG, name: "GiftLog", component: GiftLog, exact: true },
	{
		path: ROUTE.INS_USER,
		name: "List of users who agree to retrieve Instagram data",
		component: ListUserInstagram,
		exact: true,
	},

	{ path: ROUTE.AGENCY, name: "Agency", component: Agency, exact: true },
	{ path: ROUTE.CREATE_AGENCY, name: "Create", component: AgencyCurd, exact: true },
	{ path: ROUTE.EDIT_AGENCY, name: "Edit", component: AgencyCurd, exact: true },
	{
		path: ROUTE.CELEB_REQUEST_WITHDRAW,
		name: "CelebWithdraw",
		component: CelebRequestWithdraw,
		exact: true,
	},
	{ path: ROUTE.AGENCY_WITHDRAW, name: "AgencyWithdraw", component: AgencyWithdraw, exact: true },
	{
		path: ROUTE.AGENCY_REQUEST_WITHDRAW,
		name: "AgencyWithdraw",
		component: AgencyRequestWithdraw,
		exact: true,
	},
	{ path: ROUTE.PRIVACY, name: "Privacy", component: PersonalInfomation, exact: true },
	{ path: ROUTE.NOTICE, name: "Noitice", component: Noitice, exact: true },
	{ path: ROUTE.CREATE_NOTICE, name: "CreateNoitice", component: NoiticeCrud },
	{ path: ROUTE.EDIT_NOTICE, name: "EditNoitice", component: NoiticeCrud, exact: true },
	{ path: ROUTE.APPROVE_POST, name: "Approve Post", component: ApprovePost, exact: true },
	{ path: ROUTE.FAQ, name: "faq", component: FAQ, exact: true },
	{ path: ROUTE.CREATE_FAQ, name: "CreateFAQ", component: FAQCurd, exact: true },
	{ path: ROUTE.EDIT_FAQ, name: "EditFAQ", component: FAQCurd, exact: true },
	{ path: ROUTE.QNA, name: "qna", component: Inquiry, exact: true },
	{ path: ROUTE.VIDEO_CALL_LOG, name: "VideoCallLog", component: VideoCallLog, exact: true },
	{ path: ROUTE.CHAT_LOG, name: "ChatLog", component: ChatLog, exact: true },
	{ path: ROUTE.DISCOVERY, exact: true, name: "Discovery", component: DiscoveryList },
	{ path: ROUTE.CREATE_DISCOVERY, exact: true, name: "CreateDiscovery", component: DiscoveryCRUD },
	{ path: ROUTE.SEARCH, exact: true, name: "SEARCH", component: SearchList },
	{ path: ROUTE.CREATE_SEARCH, exact: true, name: "CreateSearch", component: SearchCRUD },

	{
		path: ROUTE.DEFAULT_MESS,
		name: " Default Mess",
		component: DefaultMess,
		exact: true,
	},
	{
		path: ROUTE.SETTING_DEFAULT_MESS,
		name: "Setting Default Mess",
		component: SettingDefaultMess,
		exact: true,
	},
	{
		path: ROUTE.DEFAULT_MESS_2,
		name: " Default Mess",
		component: DefaultMessII,
		exact: true,
	},
	{
		path: ROUTE.SETTING_DEFAULT_MESS_2,
		name: "Setting Default Mess",
		component: SettingDefaultMessII,
		exact: true,
	},
	{
		path: ROUTE.AUTO_MESS_ASP,
		name: "Automatic message management for ASP only",
		component: AutoMessASP,
		exact: true,
	},
	{
		path: ROUTE.SETTING_AUTO_MESS_ASP,
		name: "Automatic message settings management for ASP only",
		component: SettingAutoMessASP,
		exact: true,
	},
	{
		path: ROUTE.MONTHLY_SALE,
		name: "Monthly Sale",
		component: MonthLySaleListList,
		exact: true,
	},
	{
		path: ROUTE.DAILY_SALE,
		name: "Daily Sale",
		component: DailySaleListList,
		exact: true,
	},
	{
		path: ROUTE.PURCHASE_HISTORY,
		name: "Purchase History",
		component: PurchaseHistoryList,
		exact: true,
	},
	{
		path: ROUTE.SUBSCRIBER_HISTORY,
		name: "Subscriber History",
		component: SubscriberHistoryList,
		exact: true,
	},
	{
		path: ROUTE.WEB_PURCHASE_HISTORY,
		name: "Web Purchase History",
		component: WebPurchaseHistoryList,
		exact: true,
	},
	{
		path: ROUTE.ACCUMULATED_STAR_CELEB,
		name: "accumulating stars celeb",
		component: starCelebaccumulated,
		exact: true,
	},
	{
		path: ROUTE.CELEB_CONNECTION_STATUS,
		name: "Celeb Connection Status",
		component: CelebConnectionStatus,
		exact: true,
	},
	{
		path: ROUTE.ACTIVITY_STATUS_CELEB_MANAGEMENT,
		name: "Activity status celeb management",
		component: ActivityStatusCelebManagement,
		exact: true,
	},
	// asp
	{
		path: ROUTE.DAILY_SALE_ASP,
		name: "Sale",
		component: DailySaleASP,
		exact: true,
	},
	{
		path: ROUTE.DAILY_SALE_ASP_DETAIL,
		name: "Sale",
		component: DailySaleASPDetail,
		exact: true,
	},
	{
		path: ROUTE.MONTHLY_SALES_ASP,
		name: "Sale",
		component: MonthlySaleASP,
		exact: true,
	},
	{
		path: ROUTE.QnA_ASP,
		name: "inquiry",
		component: InquiryASP,
		exact: true,
	},
	{
		path: ROUTE.Qna_ASP_CRUD,
		name: "inquiryCRUD",
		component: InquiryASPCRUD,
		exact: true,
	},
	{
		path: ROUTE.STAR_LOG_ASP,
		name: "StarLogASP",
		component: StarLogASP,
		exact: true,
	},
	{
		path: ROUTE.COIN_LOG_ASP,
		name: "CoinLogASP",
		component: CoinLogASP,
		exact: true,
	},

	{ path: ROUTE.List_User_ASP, name: "listUserASP", component: ListUserASP, exact: true },
	{
		path: ROUTE.VIDEO_CHAT_LOG_ASP,
		name: "VideoChatLogASP",
		component: VideoChatLogASP,
		exact: true,
	},
	{
		path: ROUTE.CHAT_LOG_ASP,
		name: "ChatLogASP",
		component: ChatLogASP,
		exact: true,
	},
	{
		path: ROUTE.PLUSKIT_PURCHASE_LOG_ASP,
		name: "PluskitPurchaseLogASP",
		component: PluskitPurchaseLogASP,
		exact: true,
	},
	{
		path: ROUTE.Profile_Image_ASP,
		name: "ProfileImageASP",
		component: ProfileImageASP,
		exact: true,
	},
	{
		path: ROUTE.Report_User_ASP,
		name: "ReportUserASP",
		component: ReportUserASP,
		exact: true,
	},
	{
		path: ROUTE.REELS,
		name: "Reels",
		component: Reels,
		exact: true,
	},
	{
		path: ROUTE.INTRO_ASP,
		name: "IntroASP",
		component: IntroASP,
		exact: true,
	},
	{
		path: ROUTE.CREATE_INTRO_ASP,
		name: "CreateIntroASP",
		component: IntroAspCRUD,
		exact: true,
	},
	{
		path: ROUTE.UPDATE_INTRO_ASP,
		name: "UpdateIntroASP",
		component: IntroAspCRUD,
		exact: true,
	},
	{
		path: ROUTE.ON_OFF_PLUS_KIT_MANAGEMENT,
		name: "OnOffPlusKitManagement",
		component: OnOffPlusKitManagement,
		exact: true,
	},
	{
		path: ROUTE.VIDEO_CALL_ERROR_STATISTICS,
		name: "VideoCallErrorStatistics",
		component: VideoCallErrorStatistics,
		exact: true,
	},
	{
		path: ROUTE.GIFT_REGISTRATION_MANAGEMENT,
		name: "GiftRegistrationManagement",
		component: GiftRegistrationManagement,
		exact: true,
	},
];

export const routesAntiKdisk = [
	// if have time please change this

	// asp
	{
		path: ROUTE.DAILY_SALE_ASP,
		name: "Sale",
		component: DailySaleASP,
		exact: true,
	},
	{
		path: ROUTE.DAILY_SALE_ASP_DETAIL,
		name: "Sale",
		component: DailySaleASPDetail,
		exact: true,
	},
	{
		path: ROUTE.MONTHLY_SALES_ASP,
		name: "Sale",
		component: MonthlySaleASP,
		exact: true,
	},
	{
		path: ROUTE.List_User_ASP,
		name: "listUserASP",
		component: ListUserASP,
		exact: true,
	},
	{
		path: ROUTE.Profile_Image_ASP,
		name: "profileImageASP",
		component: ProfileImageASP,
		exact: true,
	},
	{
		path: ROUTE.Report_User_ASP,
		name: "reportUserASP",
		component: ReportUserASP,
		exact: true,
	},
	{
		path: ROUTE.ASP_ACCOUNT_TESTING_MANAGEMENT,
		name: "ASPAccountTestingManagement",
		component: ASPAccountTestingManagement,
		exact: true,
	},
	{
		path: ROUTE.QnA_ASP,
		name: "inquiry",
		component: InquiryASP,
		exact: true,
	},
	{
		path: ROUTE.Qna_ASP_CRUD,
		name: "inquiryCRUD",
		component: InquiryASPCRUD,
		exact: true,
	},
	{
		path: ROUTE.STAR_LOG_ASP,
		name: "starLogASP",
		component: StarLogASP,
		exact: true,
	},
	{
		path: ROUTE.COIN_LOG_ASP,
		name: "coinLogASP",
		component: CoinLogASP,
		exact: true,
	},
	{
		path: ROUTE.VIDEO_CHAT_LOG_ASP,
		name: "videoChatLogASP",
		component: VideoChatLogASP,
		exact: true,
	},
	{
		path: ROUTE.CHAT_LOG_ASP,
		name: "chatLogASP",
		component: ChatLogASP,
		exact: true,
	},
	{
		path: ROUTE.PLUSKIT_PURCHASE_LOG_ASP,
		name: "PluskitPurchaseLogASP",
		component: PluskitPurchaseLogASP,
		exact: true,
	},
	{
		path: ROUTE.BANNER_ASP,
		name: "bannerASP",
		component: BannerASP,
		exact: true,
	},
	{
		path: ROUTE.HASHTAG_ASP,
		name: "hashtagASPList",
		component: HashtagASPList,
		exact: true,
	},
	{
		path: ROUTE.CREATE_HASHTAG_ASP,
		name: "CreateHashtagASP",
		component: HashtagASPCRUD,
		exact: true,
	},
	{
		path: ROUTE.EDIT_HASHTAG_ASP,
		name: "EditHashtagASP",
		component: HashtagASPCRUD,
		exact: true,
	},
	{
		path: ROUTE.REELS_ASP,
		name: "reelsASP",
		component: ReelsASP,
		exact: true,
	},
	{
		path: ROUTE.HOME,
		name: "SaleASP",
		component: DailySaleASP,
		exact: true,
	},
	{
		path: ROUTE.DEFAULT_MESSAGE,
		name: "defaultMessage",
		component: DefaultMessList,
		exact: true,
	},
	{
		path: ROUTE.CREATE_DEFAULT_MESSAGE,
		name: "CreateDefaultMessage",
		component: DefaultMessCRUD,
	},
	{
		path: ROUTE.EDIT_DEFAULT_MESSAGE,
		name: "EditDefaultMessage",
		component: DefaultMessCRUD,
	},
	{
		path: ROUTE.DETAIL_DEFAULT_MESSAGE,
		name: "DetailDefaultMessage",
		component: DefaultMessDetail,
	},
	{
		path: ROUTE.POLICY,
		name: "Policy",
		component: ContentList,
		exact: true,
	},
	{
		path: ROUTE.CREATE_POLICY,
		name: "CreatePolicy",
		component: ContentCRUD,
	},
	{
		path: ROUTE.EDIT_POLICY,
		name: "EditPolicy",
		component: ContentCRUD,
	},
	{
		path: ROUTE.DETAIL_POLICY,
		name: "DetailPolicy",
		component: ContentDetail,
	},
	{
		path: ROUTE.VERSION,
		name: "version",
		component: Version,
		exact: true,
	},
	{
		path: ROUTE.INTRO_ASP,
		name: "IntroASP",
		component: Version,
		exact: true,
	},
	{
		path: ROUTE.CREATE_INTRO_ASP,
		name: "CreateIntroASP",
		component: IntroAspCRUD,
		exact: true,
	},
	{
		path: ROUTE.UPDATE_INTRO_ASP,
		name: "UpdateIntroASP",
		component: IntroAspCRUD,
		exact: true,
	},
	{
		path: ROUTE.TIME_ZONE_ACCESS_STATISTICS,
		name: "TimeZoneAccessStatistics",
		component: TimeZoneAccessStatistics,
		exact: true,
	},
	{
		path: ROUTE.DAILY_ACCESS_STATISTICS,
		name: "DailyAccessStatistics",
		component: DailyAccessStatistics,
		exact: true,
	},
	{
		path: ROUTE.ACCESS_STATISTICS_DETAIL,
		name: "AccessStatisticsDetail",
		component: AccessStatisticsDetail,
		exact: true,
	},
];

export const routesContentManager = [
	{
		path: ROUTE.HOME,
		name: "IntroASP",
		component: IntroASP,
		exact: true,
	},
	{
		path: ROUTE.INTRO_ASP,
		name: "IntroASP",
		component: IntroASP,
		exact: true,
	},
	{
		path: ROUTE.CREATE_INTRO_ASP,
		name: "CreateIntroASP",
		component: IntroAspCRUD,
		exact: true,
	},
	{
		path: ROUTE.UPDATE_INTRO_ASP,
		name: "UpdateIntroASP",
		component: IntroAspCRUD,
		exact: true,
	},
	{
		path: ROUTE.PLUSKIT_VIDEO_MANAGEMENT,
		name: "PluskitVideoManagement",
		component: PluskitVideoManagement,
		exact: true,
	},
	{
		path: ROUTE.ASP_PLUSKIT_CELEB_MANAGEMENT,
		name: "Asp pluskit celeb management",
		component: ASPPluskitCelebManagement,
		exact: true,
	},
];

export const routesFansomeASP = [
	{ path: ROUTE.HOME, exact: true, name: "Dashboard", component: Dashboard },
	{ path: ROUTE.POPULAR, name: "Policy", component: PoupularList, exact: true },
	{ path: ROUTE.CREATE_POPULAR, name: "Create", component: PoupularCRUD },
	{ path: ROUTE.EDIT_POPULAR, name: "Edit", component: PoupularCRUD },

	// { path: ROUTE.POLICY, name: "Policy", component: ContentList, exact: true },
	// { path: ROUTE.VERSION, name: "Version", component: Version, exact: true },

	{ path: ROUTE.CREATE_POLICY, name: "Create", component: ContentCRUD },
	{ path: ROUTE.EDIT_POLICY, name: "Edit", component: ContentCRUD },
	{ path: ROUTE.DETAIL_POLICY, name: "Detail", component: ContentDetail },

	{ path: ROUTE.CONVERSATION_USER, name: "Conversation", component: ConversationList },

	{ path: ROUTE.PUSH_LOG, name: "Notification", component: NotificationList, exact: true },
	{ path: ROUTE.INTERMEDIARY_FEE, name: "Create", component: Intermediaryfee },
	{ path: ROUTE.UPDATE_PUSH_LOG, name: "Update", component: NotificationCRUD },
	{ path: ROUTE.REPORT, name: "Report user", component: ReportUserList, exact: true },

	{ path: ROUTE.BLOCK_USER, name: "BlockUser", component: BlockUserList, exact: true },
	{
		path: ROUTE.ACCEPT_IMAGE_PROFILE,
		name: "Profile Image",
		component: ProfileImageList,
		exact: true,
	},
	{ path: ROUTE.LIST_USER, name: "List User", component: UserList, exact: true },
	{ path: ROUTE.DELETE_ACCOUNT, name: "Delete User", component: DeleteUser, exact: true },
	{ path: ROUTE.TERMS_OF_SERVICE, name: "Term of service", component: Term, exact: true },
	{ path: ROUTE.STAR_LOG, name: "StarLog", component: StarLog, exact: true },
	{ path: ROUTE.GIFT_LOG, name: "GiftLog", component: GiftLog, exact: true },

	{ path: ROUTE.AGENCY, name: "Agency", component: Agency, exact: true },
	{ path: ROUTE.CREATE_AGENCY, name: "Create", component: AgencyCurd, exact: true },
	{ path: ROUTE.EDIT_AGENCY, name: "Edit", component: AgencyCurd, exact: true },
	{ path: ROUTE.PRIVACY, name: "Privacy", component: PersonalInfomation, exact: true },
	{ path: ROUTE.NOTICE, name: "Noitice", component: Noitice, exact: true },
	{ path: ROUTE.CREATE_NOTICE, name: "CreateNoitice", component: NoiticeCrud },
	{ path: ROUTE.EDIT_NOTICE, name: "EditNoitice", component: NoiticeCrud, exact: true },
	{ path: ROUTE.APPROVE_POST, name: "Approve Post", component: ApprovePost, exact: true },
	{ path: ROUTE.FAQ, name: "faq", component: FAQ, exact: true },
	{ path: ROUTE.CREATE_FAQ, name: "CreateFAQ", component: FAQCurd, exact: true },
	{ path: ROUTE.EDIT_FAQ, name: "EditFAQ", component: FAQCurd, exact: true },
	{ path: ROUTE.QNA, name: "qna", component: Inquiry, exact: true },
	{ path: ROUTE.VIDEO_CALL_LOG, name: "VideoCallLog", component: VideoCallLog, exact: true },
	{ path: ROUTE.CHAT_LOG, name: "ChatLog", component: ChatLog, exact: true },
	{
		path: ROUTE.MONTHLY_SALE,
		name: "Monthly Sale",
		component: MonthLySaleListList,
		exact: true,
	},
	{
		path: ROUTE.DAILY_SALE,
		name: "Daily Sale",
		component: DailySaleListList,
		exact: true,
	},
	{
		path: ROUTE.PURCHASE_HISTORY,
		name: "Purchase History",
		component: PurchaseHistoryList,
		exact: true,
	},
	{
		path: ROUTE.SUBSCRIBER_HISTORY,
		name: "Subscriber History",
		component: SubscriberHistoryList,
		exact: true,
	},
	{
		path: ROUTE.WEB_PURCHASE_HISTORY,
		name: "Web Purchase History",
		component: WebPurchaseHistoryList,
		exact: true,
	},
	{
		path: ROUTE.ACCUMULATED_STAR_CELEB,
		name: "accumulating stars celeb",
		component: starCelebaccumulated,
		exact: true,
	},
	{
		path: ROUTE.DAILY_ACCESS_STATISTICS,
		name: "DailyAccessStatistics",
		component: DailyAccessStatistics,
		exact: true,
	},
	{
		path: ROUTE.TIME_ZONE_ACCESS_STATISTICS,
		name: "TimeZoneAccessStatistics",
		component: TimeZoneAccessStatistics,
		exact: true,
	},
	{
		path: ROUTE.ACCESS_STATISTICS_DETAIL,
		name: "AccessStatisticsDetail",
		component: AccessStatisticsDetail,
		exact: true,
	},
];

export default routes;
