import axiosClient from "./axiosClient";

const token = JSON.parse(localStorage.getItem("token"));
const HOSTNAME = process.env.REACT_APP_API;
const fields = 'fields=["$all","$paranoid",{"agency":["$all","$paranoid"]}]';
const table = "user";

const memberApi = {
	// filter: (text, page, limit, column) => {
	//     let url = '';
	//     let filedFullname = '';
	//     if (
	//         column === 'following_count' ||
	//         column === 'follower_count'
	//     ) {
	//         url = `${HOSTNAME}/${table}?${filedFullname ? filedFullname : fields}&page=${page}&limit=${limit}&filter={"${column}":"${text}"}&order=[["created_at","desc"]]`;
	//     } else {
	//         url = `${HOSTNAME}/${table}?${filedFullname ? filedFullname : fields}&page=${page}&limit=${limit}&filter={"${column}":{"$iLike":"%25${text}%25"}}&order=[["created_at","desc"]]`;
	//     }

	//     console.log('=====> url', url);
	//     return axiosClient.get(url);
	// },

	filter: (text, page, limit, column) => {
		console.log("column", column);
		let url = "";
		let filedFullname = "";

		if (column === "following_count" || column === "follower_count") {
			// filter integer
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"${column}":"${text}"&order=[["created_at","desc"]]`;
		} else if (column === "gender") {
			// filter array
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"${column}":{"$iLike": "${text}"}&order=[["created_at","desc"]]`;
		}

		// else if (column === 'nickname') { // filter alias
		//     filedFullname = `fields=["$all",{"user":["nickname",{"$filter": {"nickname": {"$iLike": "%25${text}%25"} } } ]}]`;
		//     url = `${HOSTNAME}/${table}?${filedFullname ? filedFullname : fields}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
		// }
		else {
			// filter string

			if (column === "phone") {
				text = text.replace(/\+84/g, "");
				console.log("====> AQ 🥕  => text", text);
			}
			if (column === "user_type" || column === "login_type") {
				url = `${HOSTNAME}/${table}?${
					filedFullname ? filedFullname : fields
				}&page=${page}&limit=${limit}&filter={"${column}":"${text}"&order=[["created_at","desc"]]`;
			}
			url = `${HOSTNAME}/${table}?${
				filedFullname ? filedFullname : fields
			}&page=${page}&limit=${limit}&filter={"${column}":{"$iLike" : "%25${text.trim()}%25"}}&order=[["created_at","desc"]]`;
		}

		console.log("=====> url", url);
		return axiosClient.get(url);
	},

	findAll: (page, limit) => {
		const url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
		return axiosClient.get(url);
	},
	findAllInfoUser: (page, id) => {
		//const url = `${HOSTNAME}/api/v1/user_data?filter={"id":"${id}"}&order=[["created_at","desc"]]&fields=["$all",{"posts":["$all"]}]`;
		const url = `${HOSTNAME}/api/v1/post?fields=["$all"]&filter={"user_id": "${id}" }`;
		return axiosClient.get(url);
	},

	findOne: (id) => {
		const url = `${HOSTNAME}/${table}/${id}?fields=["$all"]`;
		return axiosClient.get(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	userProfile: async (id) => {
		const url = `${HOSTNAME}/${table}/${id}/admin?fields=["$all",{"user_hashtags":["id",{"hashtag":["title","id","type_belong"]}]},{"sales_histories":["$all",{"pack":["price"]}]},{"nice_infos":["$all"]},{"agency":["nickname","username"]},{"user_setting":["$all"]},{"penalty":["$all"]},{"partner_user":["$all"]}]`;
		const url1 = `${HOSTNAME}/${table}/${id}/admin?fields=["$all","$paranoid",{"user_hashtags":["id",{"hashtag":["$all"]}]},{"sales_histories":["$all",{"pack":["price"]}]},{"nice_infos":["$all"]},{"agency":["nickname","username"]},{"user_setting":["$all"]},{"penalty":["$all"]},{"partner_user":["$all"]}]`;
		const res = await axiosClient.get(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (!res.results) {
			return axiosClient.get(url1, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
		}
		return res;
	},
	// userProfileGetmoney: (id) => {
	// 	const url = `${HOSTNAME}/${table}/${id}/admin?fields=["$all"]`;
	// 	return axiosClient.get(url, {
	// 		headers: {
	// 			Authorization: `Bearer ${token}`,
	// 		},
	// 	});
	// },
	getAcceptCeleb: (page, limit, filter, sort) => {
		let order = `[[${JSON.stringify(sort.column || "created_at")}, ${JSON.stringify(
			(sort.asc && "asc") || "desc"
		)}],["processed_at_unix_timestamp","desc"]]`;
		const url = `${HOSTNAME}/${table}?${fields}&filter=${filter}&page=${page}&limit=${limit}&order=${order}`;
		return axiosClient.get(url);
	},
	getAll: (page, limit, filter) => {
		console.log("getALL user");
		const url = `${HOSTNAME}/${table}/admin/get_list_cms?fields=["$all",{"agency":["username"]},{"user_hashtags":["id",{"hashtag":["title"]}]},{"usage_star":["$all"]},{"user_setting":["$all"]}]&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
		return axiosClient.get(url);
	},

	add: (body) => {
		const url = `${HOSTNAME}/api/v1/${table}`;
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},

	register: (body) => {
		const url = `${HOSTNAME}/api/v1/user`;
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	getCelebUser: (page = 1, limit = 10) => {
		const url = `${HOSTNAME}/${table}?${'fields=["$all"]'}&page=${page}&limit=${limit}&order=[["created_at","desc"]]&filter={"user_type":"CELEBRITY","register_status":"ACCEPT"}`;
		return axiosClient.get(url);
	},

	getCelebUserIntermediaryFee: (page = 1, limit = 10, filter, sort) => {
		let order = `[[${JSON.stringify(sort.column || "updated_at")}, ${JSON.stringify(
			(sort.asc && "asc") || "desc"
		)}],["processed_at_unix_timestamp","desc"]]`;
		const url = `${HOSTNAME}/${table}?${'fields=["$all", {"agency":["username"]}]'}&page=${page}&limit=${limit}&filter=${encodeURIComponent(
			filter
		)}&order=${order}`;
		return axiosClient.get(url);
	},

	getAllCelebUser: () => {
		const url = `${HOSTNAME}/${table}?${'fields=["$all"]'}&limit=${1000}&order=[["created_at","desc"]]&filter={"$and":[{"user_type":"CELEBRITY"},{"register_status":"ACCEPT"}]}`;
		return axiosClient.get(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},

	getRegister: ({ text, colFilter, currentPage, LIMITED, statusFilter }) => {
		const column = colFilter;
		const page = currentPage;
		const limit = LIMITED;
		let url = "";
		let query = "";
		if (text && column) {
			query += `"${column}":{"$iLike":"%25${text}%25"},`;
		}
		if (statusFilter) {
			query += `"state_register":"${statusFilter}",`;
		}
		query = query.substring(0, query.length - 1);
		url = `${HOSTNAME}/${table}?${fields}&page=${page}&limit=${limit}&order=[["created_at","desc"]]&filter={"state":{"$ne":"DELETE"},"state_register":{"$ne":null}${
			query && `,${query}`
		}}`;
		console.log("Tuấn Anh ~ file: MemberApi.js ~ line 102 ~ url", url);
		return axiosClient.get(url);
	},

	update: (body, id) => {
		const url = `${HOSTNAME}/${table}/${id}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	appcetManyCeleb: (filter, body) => {
		const url = `${HOSTNAME}/${table}/admin/accept_multi_celeb?filter={"id" : ${filter}}`;
		return axiosClient.put(
			url,
			{
				register_status: body,
				intermediary_fee: body === "ACCEPT" ? 20 : 0,
			},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
	},
	appcetMany: (filter, body) => {
		const url = `${HOSTNAME}/${table}/admin/update_multi?filter={"id" : ${filter}}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},

	delete: (id) => {
		const url = `${HOSTNAME}/user/${id}`;
		console.log("url", url);
		return axiosClient.delete(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	deleteMulti: (body, ids) => {
		const url = `${HOSTNAME}/${table}/admin/accept_multi_celeb?filter={"id" : ${ids}}`;
		console.log("url", url);
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	uploadFile: (body) => {
		const url = `${HOSTNAME}/image/upload/`;
		return axiosClient.post(url, body, {
			headers: {
				"Content-Type": "image/png",
				Authorization: `Bearer ${token}`,
			},
		});
	},
	approveOrDenyAvatarRequest: (body, id) => {
		const url = `${HOSTNAME}/photo_request/${id}`;
		return axiosClient.put(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},

	getSubcriptionCeleb: (page, limit, filter) => {
		const fields =
			'fields=["$all",{"subscribers" :["$all",{"user" : ["$all"]},{"$filter": {"id": {"$not": null}}}]}]';
		const url = `${HOSTNAME}/${table}?${fields}&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
		return axiosClient.get(url);
	},
	getDeleteAccount: (page, limit, filter) => {
		const fields = 'fields=["$all",{"agency":["id","username"]}]';
		const url = `${HOSTNAME}/${table}/deleted_user?${fields}&filter=${filter}&page=${page}&limit=${limit}&order=[["deleted_at","desc"]]`;
		return axiosClient.get(url);
	},
	adminUpdate: (body, id) => {
		const url = `${HOSTNAME}/${table}/admin/admin_update`;
		console.log("url...", url);
		return axiosClient.post(url, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	},
	userProfileASP: async (id) => {
		const url = `${HOSTNAME}/${table}/${id}/admin?fields=["$all",{"asp_user":["$all"]},{"penalty":["$all"]}]`;
		const url1 = `${HOSTNAME}/${table}/${id}/admin?fields=["$all",{"asp_user":["$all"]},"$paranoid",{"penalty":["$all"]}]`;
		const res = await axiosClient.get(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		if (!res.results) {
			return axiosClient.get(url1, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
		}
		return res;
	},
};

export default memberApi;
